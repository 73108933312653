import {Api} from 'shared/services';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IAnswerSummarySectionFilters {
  papel: string;
  secao: string;
  tipoQuestao: string;
  disciplina?: string;
  curso?: string;
  coordenador?: string;
  turma?: string;
}

export interface IAudienceComposition {
  papel: string;
  valor: number;
}

export interface IBurnDown {
  data: string;
  valorEsperado: number;
  valorExecutado: number;
}

export interface IMultipleOptionsTypeAnswer {
  resposta: string;
  papeis: {
    papel: string;
    numeroDeRespostas: number;
  }[];
}

export interface ISatisfactionTypeAnswer {
  resposta: string;
  satisfacao: string;
  papeis: {
    papel: string;
    numeroDeRespostas: number;
  }[];
}

export interface IGridOptionsTypeAnswer {
  linha: {
    ordem: number;
    item: string;
    idOpcaoRespostaLinha: string;
    colunas: {
      Ordem: number;
      Item: string;
      IdOpcaoRespostaColuna: string;
      Valor: number;
    }[];
  }[];
}

export interface IDateTimeTypeAnswer {
  maioresOcorrencias: {
    dataHora: string;
    quantidade: number;
  }[];
  menoresOcorrencias: {
    dataHora: string;
    quantidade: number;
  }[];
}

export interface ITextualTypeAnswer {
  respostasTexto: string[];
}

export interface IQuestionDetail
  extends IMultipleOptionsTypeAnswer,
    IGridOptionsTypeAnswer,
    IDateTimeTypeAnswer,
    ISatisfactionTypeAnswer,
    ITextualTypeAnswer {}

export interface IAnswerSummaryQuestion {
  id: string;
  enunciado: string;
  descricao: string;
  tipoQuestao: string;
  detalhes: IQuestionDetail[];
  respostasRecebidas: number;
  comentarios: number;
  respostasPendentes: number;
}

export interface IAnswerSummarySection {
  id: string;
  ordem: number;
  multiplicador: boolean;
  nomeSecao: string;
  questoes: IAnswerSummaryQuestion[];
}

export interface IAnswerSummary {
  tituloConvite: string;
  convitesEnviados: number;
  questionariosRecebidos: number;
  percentualGeral: number;
  percentualParticipacao: number;
  respostasAnonimas: number;
  ativo: boolean;
  dataHoraFim: string;
  numeroConviteSemRespostas: number;
  tokenRelatorio?: string;
}

export interface IQuestionODataType {
  id: string;
  nomePessoa: string;
  idQuestao: string;
  enunciadoQuestao: string;
  satisfacao: string;
  idPessoaConvite: string;
  comentario: string;
  tipoQuestao: string;
  respostaLinha: string;
  respostaColuna: string;
  idLinhaSelecionado: string;
  idColunaSelecionado: string;
  codigoUsuario: string;
  papel: string;
  matricula: number;
  curso: string;
  nomeDisciplina?: string;
  multiplicarDisciplina: boolean;
  nomeTurma: string;
  idTurma: string;
}

export interface IGroupedByPropertyType {
  [key: string]: IQuestionODataType[];
}

export interface IAnswerSummaryData {
  filtrosBurndownComposicaoPublico: {
    composicaoPublico: IAudienceComposition[];
    burnDown: IBurnDown[];
    filtros: {
      nome: string;
      itens: {
        id: string;
        nome: string;
      }[];
    }[];
  };
  dadosSecoes: IAnswerSummarySection[];
}

export interface IDownloadReport {
  fileContent: string;
}

export interface ISectionList {
  id: string;
  nome: string;
  ordem: number;
}

export interface ICourseTeacher {
  Id: number;
  Nome: string;
}

export interface ICourses {
  Nome: string;
  Professores: ICourseTeacher[];
}

export interface ICoursesFilter {
  Cursos: ICourses[];
}

export interface IPostFilterCourse {
  Nome: string;
  Professores: number[];
}

export interface IPostCourses {
  IdConvite: string;
  Cursos: IPostFilterCourse;
}

export interface IPostFilterSubject {
  Nome: string;
  Professores: number[];
}
export interface IPostSubject {
  IdConvite: string;
  Disciplinas: IPostFilterSubject;
}

export interface ISubjects {
  Id: number;
  Nome: string;
  Professores: ICourseTeacher[];
}
export interface ISubjectsFilter {
  Disciplinas: ISubjects[];
}

const AnswerSumarrySubject = async (
  idConvite: string,
): Promise<Promise<ISubjectsFilter>> => {
  try {
    const {data} = await Api.get(
      `/relatorios/filtros/disciplinas/${idConvite}`,
    );
    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const postSubjectPrint = async (payload: IPostSubject): Promise<any> => {
  try {
    const {data} = await Api.post(`/relatorios/impressao/disciplinas`, payload);

    return {data};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.mensagens}`);
  }
};

const getCoursesFilter = async (idConvite: string): Promise<ICoursesFilter> => {
  try {
    const {data: response} = await Api.get(
      `/relatorios/filtros/cursos/${idConvite}`,
    );

    return response;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const postCoursesFilter = async (courses: IPostCourses): Promise<string> => {
  try {
    const {data: response} = await Api.post(
      `/relatorios/impressao/cursos/`,
      courses,
    );
    return response;
  } catch (error: any) {
    throw new Error(error.mensagens);
  }
};

const getQuestionByOData = async (
  idConvite: string,
  idQuestao: string,
  extraFilters?: string,
): Promise<IResultOfAction<IQuestionODataType>> => {
  try {
    const {data} = await Api.get(
      `/odata/RespostasEncerradas?$select=IdGrupoPapel,IdCoordenador,IdSecao,IdCurso,IdDisciplina,NomeTurma,IdTurma,MultiplicarDisciplina,Id,NomeDisciplina,NomePessoa,IdQuestao,Enunciado,IdPessoaConvite,Comentario,TipoQuestao,RespostaLinha,RespostaColuna,IdLinhaSelecionado,IdColunaSelecionado,CodigoUsuario,Papel,Matricula,DescricaoCurso&$filter=Id eq ${idConvite} and IdQuestao eq ${idQuestao} ${
        extraFilters !== '' ? '' + extraFilters : ''
      }`,
    );

    const questionSummary: IQuestionODataType = data.value.map(
      (dataValue: any) => ({
        id: dataValue.Id,
        nomePessoa: dataValue.NomePessoa,
        idQuestao: dataValue.IdQuestao,
        enunciadoQuestao: dataValue.Enunciado,
        idPessoaConvite: dataValue.IdPessoaConvite,
        comentario: dataValue.Comentario,
        tipoQuestao: dataValue.TipoQuestao,
        respostaLinha:
          dataValue.RespostaLinha === '01/01/1900'
            ? ''
            : dataValue.RespostaLinha,
        respostaColuna: dataValue.RespostaColuna,
        idLinhaSelecionado: dataValue.IdLinhaSelecionado,
        idColunaSelecionado: dataValue.IdColunaSelecionado,
        codigoUsuario: dataValue.CodigoUsuario,
        papel: dataValue.Papel,
        matricula: dataValue.Matricula,
        curso: dataValue.DescricaoCurso,
        nomeDisciplina: dataValue.NomeDisciplina,
        multiplicarDisciplina: dataValue.MultiplicarDisciplina,
        nomeTurma: dataValue.NomeTurma,
        idTurma: dataValue.IdTurma,
      }),
    );

    const response: IResultOfAction<IQuestionODataType> = {
      data: questionSummary,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getAnswerSummaryById = async (
  idConvite: string,
  filters: IAnswerSummarySectionFilters | undefined,
): Promise<IResultOfAction<IAnswerSummary>> => {
  try {
    const {data} = await Api.get(
      `/relatorios/${idConvite}/resumo?papeis=${filters?.papel || ''}&secoes=${
        filters?.secao || ''
      }&tipoQuestao=${filters?.tipoQuestao || ''}&coordenador=${
        filters?.coordenador || ''
      }&disciplinas=${filters?.disciplina || ''}&cursos=${
        filters?.curso || ''
      }&turmas=${filters?.turma || ''}`,
    );

    const reportSummary: IAnswerSummary = {
      tituloConvite: data.TituloConvite,
      convitesEnviados: data.ConvitesEnviados,
      questionariosRecebidos: data.QuestionariosRecebidos,
      percentualParticipacao: data.PercentualParticipacao,
      percentualGeral: data.PercentualGeral,
      respostasAnonimas: data.RespostasAnonimas,
      ativo: data.Ativo,
      dataHoraFim: data.DataHoraFim,
      numeroConviteSemRespostas: data.NumeroConvitesSemRespostas,
      tokenRelatorio: data.TokenRelatorio,
    };

    const response: IResultOfAction<IAnswerSummary> = {
      data: reportSummary,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getAnswerSummarySectionsById = async (
  idConvite: string,
  filters: IAnswerSummarySectionFilters | undefined,
): Promise<IResultOfAction<IAnswerSummaryData>> => {
  try {
    const {data} = await Api.get(
      `/relatorios/${idConvite}/resumo/secoes?papeis=${
        filters?.papel || ''
      }&secoes=${filters?.secao || ''}&tipoQuestao=${
        filters?.tipoQuestao || ''
      }&coordenador=${filters?.coordenador || ''}&disciplinas=${
        filters?.disciplina || ''
      }&cursos=${filters?.curso || ''}&turmas=${filters?.turma || ''}`,
    );

    const reportSummarySection: IAnswerSummaryData = {
      filtrosBurndownComposicaoPublico: {
        burnDown:
          data.FiltrosBurndownComposicaoPublico.BurnDown !== null
            ? data.FiltrosBurndownComposicaoPublico.BurnDown.map((bn: any) => ({
                data: bn.Data,
                valorEsperado: bn.ValorEsperado,
                valorExecutado: bn.ValorExecutado,
              }))
            : [],
        composicaoPublico:
          data.FiltrosBurndownComposicaoPublico.ComposicaoPublico[0] !== null
            ? data.FiltrosBurndownComposicaoPublico.ComposicaoPublico.map(
                (comp: any) => ({
                  papel: comp.Papel,
                  valor: comp.Valor,
                }),
              )
            : [],
        filtros:
          data.FiltrosBurndownComposicaoPublico.Filtros !== null
            ? data.FiltrosBurndownComposicaoPublico.Filtros.map(
                (filtro: any) => ({
                  nome: filtro.Nome,
                  itens: filtro.Itens.map((filtroItens: any) => ({
                    id: filtroItens.Id,
                    nome: filtroItens.Nome,
                  })),
                }),
              )
            : [],
      },
      dadosSecoes:
        data.DadosSecoes.length > 0
          ? data.DadosSecoes.map((sec: any) => ({
              id: sec.Id,
              ordem: sec.Ordem,
              nomeSecao: sec.NomeSecao,
              multiplicador: sec.TemMultiplicador,
              questoes: sec.Questoes.map((questao: any) => ({
                id: questao.IdQuestao,
                enunciado: questao.Enunciado,
                descricao: questao.Descricao,
                tipoQuestao: questao.TipoQuestao,
                detalhes: questao.Detalhes.map((questao: any) => ({
                  respostasTexto: questao.RespostasTexto,
                  resposta: questao.Resposta,
                  satisfacao: questao.Satisfacao,
                  papeis: questao.Papeis.map((p: any) => ({
                    papel: p.Papel,
                    numeroDeRespostas: p.NumeroDeRespostas,
                  })),
                  maioresOcorrencias: questao.MaioresOcorrencias.map(
                    (maioresocurr: any) => ({
                      dataHora: maioresocurr.DataHora,
                      quantidade: maioresocurr.Quantidade,
                    }),
                  ),
                  menoresOcorrencias: questao.MenoresOcorrencias.map(
                    (menoresocurr: any) => ({
                      dataHora: menoresocurr.DataHora,
                      quantidade: menoresocurr.Quantidade,
                    }),
                  ),
                  linha: questao.Linha.map((row: any) => ({
                    ordem: row.Ordem,
                    item: row.Item,
                    idOpcaoRespostaLinha: row.IdOpcaoRespostaLinha,
                    colunas: row.Colunas,
                  })),
                })),
                respostasRecebidas: questao.RespostasRecebidas,
                comentarios: questao.Comentarios,
                respostasPendentes: questao.RespostasPendentes,
              })),
            }))
          : [],
    };

    const response: IResultOfAction<IAnswerSummaryData> = {
      data: reportSummarySection,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const downloadReport = async (
  idConvite: string,
  respostasTextuais: boolean,
): Promise<IResultOfAction<IDownloadReport>> => {
  try {
    const {data} = await Api.get(
      `/convites/respostasEncerradas/download/${idConvite}?comRepostaTexto=${respostasTextuais}`,
    );

    const downloadObject: IDownloadReport = {
      fileContent: data,
    };

    const response: IResultOfAction<IDownloadReport> = {
      data: downloadObject,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const downloadGroupedByCourse = async (
  idConvite: string,
): Promise<IResultOfAction<IDownloadReport>> => {
  try {
    const {data} = await Api.get(`/relatorios/agrupamento/cursos/${idConvite}`);

    const downloadObject: IDownloadReport = {
      fileContent: data,
    };

    const response: IResultOfAction<IDownloadReport> = {
      data: downloadObject,
      success: true,
      message: '',
    };

    return response;
  } catch (error: any) {
    const response: IResultOfAction<any> = {
      data: null,
      success: false,
      message: error.data?.data?.Message || 'Erro ao carregar dados para download.',
    };
    return response;
  }
};

const downloadGroupedBySubject = async (
  idConvite: string,
): Promise<IResultOfAction<IDownloadReport>> => {
  try {
    const {data} = await Api.get(
      `/relatorios/agrupamento/disciplinas/${idConvite}`,
    );

    const downloadObject: IDownloadReport = {
      fileContent: data,
    };

    const response: IResultOfAction<IDownloadReport> = {
      data: downloadObject,
      success: true,
      message: '',
    };

    return response;
  } catch (error: any) {
    const response: IResultOfAction<any> = {
      data: null,
      success: false,
      message: error.data?.data?.Message || 'Erro ao carregar dados para download.',
    };
    return response;
  }
};

const downloadGroupedTextAnswer = async (
  idConvite: string,
): Promise<IResultOfAction<IDownloadReport>> => {
  try {
    const {data} = await Api.get(
      `/relatorios/agrupamento/respostastexto/${idConvite}`,
    );

    const downloadObject: IDownloadReport = {
      fileContent: data,
    };

    const response: IResultOfAction<IDownloadReport> = {
      data: downloadObject,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getAllAnswerSummarySectionsById = async (
  idConvite: string,
): Promise<IResultOfAction<ISectionList[]>> => {
  try {
    const {data} = await Api.get(`/relatorios/${idConvite}/listaSecoes`);

    const sectionsList: ISectionList[] = data.map((sec: any) => ({
      id: sec.Id,
      nome: sec.Name,
      ordem: sec.Ordem,
    }));

    const response: IResultOfAction<ISectionList[]> = {
      data: sectionsList,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const AnswerSumarryTeachers = async (
  idConvite: string,
): Promise<Promise<ICourseTeacher[]>> => {
  try {
    const {data} = await Api.get<ICourseTeacher[]>(
      `/relatorios/filtros/professores/${idConvite}`,
    );

    return data;
  } catch (error: any) {
    throw new Error(`Erro ao listar. ${error.mensagens}`);
  }
};

const postTeacherPrint = async (payload: any): Promise<any> => {
  try {
    const {data} = await Api.post(`/relatorios/impressao/professores`, payload);

    return {data};
  } catch (error: any) {
    throw new Error(`Erro ao salvar. ${error.mensagens}`);
  }
};

export const AnswerSummaryService = {
  getAnswerSummaryById,
  getAnswerSummarySectionsById,
  getQuestionByOData,
  downloadReport,
  downloadGroupedByCourse,
  downloadGroupedBySubject,
  downloadGroupedTextAnswer,
  getAllAnswerSummarySectionsById,
  getCoursesFilter,
  postCoursesFilter,
  AnswerSumarryTeachers,
  postTeacherPrint,
  AnswerSumarrySubject,
  postSubjectPrint,
};
