import React, {memo, useCallback, useEffect, useState} from 'react';
import {
  DeleteOutlined,
  FileCopyOutlined,
  InfoOutlined,
} from '@mui/icons-material';

import {
  Box,
  FormControl,
  Grid,
  Typography,
  ClickAwayListener,
  useTheme,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Theme,
  Divider,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {SectionMenu} from './SectionMenu';
import {Question} from '../question/Question';
import {useSection, useQuiz} from 'pages/quiz/hooks';
import {questionObservable} from 'pages/quiz/observables/Observable';
import {useField} from '@unform/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {confirm} from 'shared/services';

const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 9,
  },
  icon: {
    pointerEvents: 'auto',
  },
}));

interface ISectionProps {
  sectionOrder: number;
  isDefaultExpanded?: boolean;
}

export const Section: React.FC<ISectionProps> = memo(
  ({sectionOrder, isDefaultExpanded}) => {
    const classes = useStyles();

    const {error: nameError, clearError: nameClearError} = useField(
      `sections[${sectionOrder - 1}].name`,
    );

    const {error: paperGroupError, clearError: paperGroupClearError} = useField(
      `sections[${sectionOrder - 1}].paperGroups`,
    );

    const {error: noQuestionError, clearError: noQuestionClearError} = useField(
      `sections[${sectionOrder - 1}].questions`,
    );

    const {error: noTeacherStatusError, clearError: noTeacherStatusClearError} = useField(
      `sections[${sectionOrder - 1}].evaluationIntent`,
    );

    const theme = useTheme();

    const {setIsEditingQuiz, hasInviteSent, erpIntegracao} = useQuiz();

    const {
      getSession,
      setSectionName,
      setSectionDescription,
      getSessionName,
      getSessionDescription,
      getQuestionsOrders,
      getSectionIsSelected,
      setSectionIsSelected,
      getSectionPapers,
      duplicateSection,
      deleteSection,
      setSectionMultiplyDiscipline,
      getSessionMultiplyDiscipline,
      getSessionMultiplyCourse,
      setSectionMultiplyCourse,
      getSectionEvaluationIntent,
      setSectionEvaluationIntent,
      setEvaluationIntentType,
      getEvaluationIntentType
    } = useSection(sectionOrder);

    const [name, setName] = useState(getSessionName());
    const [description, setDescription] = useState(getSessionDescription());
    const [questionsOrders, setQuestionOrders] = useState(getQuestionsOrders());
    const [isSelected, setIsSelected] = useState(getSectionIsSelected());
    const [multiplyDiscipline, setMultiplyDiscipline] = useState(
      getSessionMultiplyDiscipline(),
    );
    const [multiplyCourse, setMultiplyCourse] = useState(
      getSessionMultiplyCourse(),
    );
    const [evaluationIntent, setEvaluationIntent] = useState(
      String(getSectionEvaluationIntent()) || '1',
    );

    const [statusProfessor, setStatusProfessor] = useState({
      professorResponsavel: true,
      professorAtivo: false
    });

    const [isExpanded, setIsExpanded] = useState(!!isDefaultExpanded);

    useEffect(() => {
      setName(getSessionName());
      setDescription(getSessionDescription());
      setQuestionOrders(getQuestionsOrders());
      setStatusProfessor(getEvaluationIntentType());
    }, [getEvaluationIntentType, getQuestionsOrders, getSessionDescription, getSessionName]);

    useEffect(() => {
      getSectionPapers.length > 0 && paperGroupClearError();
    }, [getSectionPapers, paperGroupClearError]);

    useEffect(() => {
      questionsOrders.length > 0 && noQuestionClearError();
    }, [noQuestionClearError, questionsOrders.length]);

    useEffect(() => {
      const listener = questionObservable.subscribe(() => {
        setQuestionOrders(getQuestionsOrders());
      });
      return () => listener.unsubscribe();
    }, [getQuestionsOrders]);

    const onChangeName = useCallback(
      (name: string) => {
        setName(name);
        setSectionName(name);
        setIsEditingQuiz(true);
        nameError && nameClearError();
      },
      [nameClearError, nameError, setSectionName, setIsEditingQuiz],
    );

    const onChangeDescription = useCallback(
      (description: string) => {
        setDescription(description);
        setSectionDescription(description);
        setIsEditingQuiz(true);
      },
      [setSectionDescription, setIsEditingQuiz],
    );

    const onChangeIsSelected = useCallback(
      (value: boolean) => {
        setIsSelected(value);
        setSectionIsSelected(value);
      },
      [setSectionIsSelected],
    );

    const handleCloseDuplicateSection = useCallback(() => {
      duplicateSection();
      setIsEditingQuiz(true);
    }, [duplicateSection, setIsEditingQuiz]);

    const handleCloseDeleteSection = useCallback(() => {
      setIsEditingQuiz(true);
      confirm('Tem certeza que deseja remover esta seção?', 'delete', () =>
        deleteSection(),
      );
    }, [deleteSection, setIsEditingQuiz]);

    const handleCheckMultiplyDiscipline = useCallback(
      (value: boolean) => {
        setEvaluationIntent('1');
        setSectionEvaluationIntent('1');
        setMultiplyDiscipline(value);
        setSectionMultiplyDiscipline(value);
        setMultiplyCourse(false);
        setSectionMultiplyCourse(false);
        setIsEditingQuiz(true);
      },
      [
        setSectionEvaluationIntent,
        setSectionMultiplyDiscipline,
        setSectionMultiplyCourse,
        setIsEditingQuiz,
      ],
    );

    const handleCheckMultiplyCourse = useCallback(
      (value: boolean) => {
        setEvaluationIntent('1');
        setSectionEvaluationIntent('1');
        setMultiplyCourse(value);
        setSectionMultiplyCourse(value);
        setMultiplyDiscipline(false);
        setSectionMultiplyDiscipline(false);
        setIsEditingQuiz(true);
      },
      [
        setSectionEvaluationIntent,
        setSectionMultiplyCourse,
        setSectionMultiplyDiscipline,
        setIsEditingQuiz,
      ],
    );

    const handleChangeEvaluationIntent = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEditingQuiz(true);
        setEvaluationIntent(event.target.value);
        setSectionEvaluationIntent(event.target.value);
      },
      [setIsEditingQuiz, setSectionEvaluationIntent],
    );

    const handleChangeTipoIntencaoAvaliacao = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const tipoIntencaoAvaliacao = getEvaluationIntentType();

      const evaluationIntent = {
        ...tipoIntencaoAvaliacao,
        [event.target.name]: event.target.checked
      }

      setStatusProfessor(evaluationIntent);
      setEvaluationIntentType(evaluationIntent);
      
    }, [getEvaluationIntentType, setEvaluationIntentType]);


    return (
      <ClickAwayListener onClickAway={() => onChangeIsSelected(false)}>
        <Box
          borderRadius={5}
          boxShadow={3}
          p={1}
          marginTop={3}
          onClick={() => onChangeIsSelected(true)}
          onBlur={() => onChangeIsSelected(false)}
          style={{
            transition: 'border-color 0.3s ease',
            backgroundColor: theme.palette.common.white,
            borderLeft: isSelected
              ? `10px solid ${theme.palette.grey[300]}`
              : `10px solid ${theme.palette.common.white}`,
          }}>
          <Accordion
            id={getSession().id}
            key={getSession().id}
            style={{boxShadow: 'none'}}
            expanded={isExpanded}
            onChange={(_, expand) => {
              setIsExpanded(expand);
            }}>
            <AccordionSummary
              key={`${sectionOrder}-accordion-summary`}
              className={classes.summary}
              expandIcon={<ExpandMoreIcon className={classes.icon} />}>
              <Divider />
              <Box
                width={'100%'}
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Typography
                  variant="h6"
                  color="primary"
                  style={{
                    fontWeight: 'bold',
                    wordBreak: 'break-all',
                    maxWidth: 850,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                  Seção {sectionOrder}
                  {name !== '' && ` - ${name}`}
                </Typography>
              </Box>
              <Box display="flex" alignItems={'center'}>
                {hasInviteSent ? (
                  <>
                    <IconButton
                      disabled={hasInviteSent}
                      size={'small'}
                      style={{pointerEvents: 'auto'}}
                      onClick={handleCloseDeleteSection}>
                      <DeleteOutlined />
                    </IconButton>
                    <IconButton
                      disabled={hasInviteSent}
                      size={'small'}
                      style={{pointerEvents: 'auto'}}
                      onClick={handleCloseDuplicateSection}>
                      <FileCopyOutlined />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Tooltip title="Excluir essa seção">
                      <IconButton
                        disabled={hasInviteSent}
                        size={'small'}
                        style={{pointerEvents: 'auto'}}
                        onClick={handleCloseDeleteSection}>
                        <DeleteOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Duplicar essa seção">
                      <IconButton
                        disabled={hasInviteSent}
                        size={'small'}
                        style={{pointerEvents: 'auto'}}
                        onClick={handleCloseDuplicateSection}>
                        <FileCopyOutlined />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <SectionMenu
                  openMoveSectionModal={() => {}}
                  sectionOrder={sectionOrder}
                />
              </Box>
            </AccordionSummary>
            <Box paddingLeft={2} /*paddingBottom={3}*/>
              {!!noQuestionError && (
                <Typography variant="subtitle2" color="error">
                  É preciso criar pelo menos uma questão para uma seção.
                </Typography>
              )}
              {!!paperGroupError && (
                <Typography variant="subtitle2" color="error">
                  É preciso selecionar grupos de papéis para esta seção.
                </Typography>
              )}
            </Box>
            {isExpanded ? (
              <AccordionDetails key={`${sectionOrder}-accordion-details`}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Tooltip title="Informe o título da seção">
                      <TextField
                        variant="outlined"
                        label={`Título da seção`}
                        value={name}
                        onChange={(e) => onChangeName(e.target.value)}
                        placeholder="Título da seção"
                        fullWidth
                        multiline
                        required
                        error={!!nameError}
                        helperText={nameError || name.length + '/510'}
                        inputProps={{
                          maxLength: 510,
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title="informe a descrição da seção">
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant={'outlined'}
                          fullWidth
                          multiline
                          label={'Descrição da seção'}
                          onChange={(e) => onChangeDescription(e.target.value)}
                          value={description}
                          helperText={description.length + '/1020'}
                          inputProps={{
                            maxLength: 1020,
                          }}
                        />
                      </FormControl>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      marginBottom={3}
                      display="flex"
                      justifyContent={'space-between'}
                      alignItems={'center'}>
                      <Box>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Multiplicar para
                          </Typography>
                        </Box>
                        <FormControlLabel
                          disabled={hasInviteSent}
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              onChange={(e) =>
                                handleCheckMultiplyDiscipline(e.target.checked)
                              }
                              checked={multiplyDiscipline}
                            />
                          }
                          label={
                            <Typography
                              variant="caption"
                              noWrap
                              component={'b'}>
                              Componente curricular
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          disabled={hasInviteSent}
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              onChange={(e) =>
                                handleCheckMultiplyCourse(e.target.checked)
                              }
                              checked={multiplyCourse}
                            />
                          }
                          label={
                            <Typography
                              variant="caption"
                              noWrap
                              component={'b'}>
                              Curso
                            </Typography>
                          }
                        />
                      </Box>
                      {multiplyDiscipline && erpIntegracao === 'GVcentris' && (
                        <Box>
                          <Box>
                            <Typography variant="caption" color="textSecondary">
                              Intenção de avaliação
                            </Typography>
                            <Tooltip
                              placement="right"
                              title="Ao selecionar a intenção de avaliação por professor, apenas os alunos terão as questões multiplicadas.">
                              <IconButton>
                                <InfoOutlined fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box display={'flex'}>
                            <RadioGroup
                              value={evaluationIntent}
                              row
                              onChange={(e) => handleChangeEvaluationIntent(e)}>
                              <FormControlLabel
                                value="1"
                                control={
                                  <Radio
                                    disabled={hasInviteSent}
                                    size="small"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="caption"
                                    noWrap
                                    component={'b'}>
                                    Disciplina
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                value="2"
                                control={
                                  <Radio
                                    disabled={hasInviteSent}
                                    size="small"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="caption"
                                    noWrap
                                    component={'b'}>
                                    Professor
                                  </Typography>
                                }
                              />
                            </RadioGroup>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    {evaluationIntent === '2' && 
                      <Box marginBottom={3}>
                        <Box>
                          <Typography variant="caption" color="textSecondary">
                            Status do professor
                          </Typography>
                        </Box>
                          <FormControlLabel label={
                              <Typography
                                variant="caption"
                                noWrap
                                component={'b'}>
                                  Responsável
                                </Typography>
                              } control={
                                <Checkbox
                                  disabled={hasInviteSent}
                                  name="professorResponsavel"
                                  checked={statusProfessor.professorResponsavel}
                                  size="small"
                                  color="primary"
                                  onChange={(e) => handleChangeTipoIntencaoAvaliacao(e)}
                                  />
                              }/> 
                              <FormControlLabel label={
                                <Typography
                                variant="caption"
                                noWrap
                                component={'b'}>
                                  Ativo
                                </Typography>
                              } control={
                                <Checkbox
                                  disabled={hasInviteSent}
                                  name="professorAtivo"
                                  checked={statusProfessor.professorAtivo}
                                  size="small"
                                  color="primary"
                                  onChange={(e) => handleChangeTipoIntencaoAvaliacao(e)}
                                  />
                              }
                                
                              />
                              {!!noTeacherStatusError && <Box>
                                <Typography variant="caption" color="error">
                                  É preciso selecionar ao menos um status para o professor
                                </Typography>
                              </Box>}
                      </Box>}
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {questionsOrders.length > 0 &&
                      questionsOrders.map(({id, order}) => {
                        return (
                          <Question
                            key={id}
                            sectionOrder={sectionOrder}
                            questionOrder={order}
                          />
                        );
                      })}
                  </Grid>
                </Grid>
              </AccordionDetails>
            ) : (
              <></>
            )}
          </Accordion>
        </Box>
      </ClickAwayListener>
    );
  },
);
